import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home";
import CreateProfile from "./pages/CreateProfile";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Router>
      <Routes>
        <Route key={Math.random()} path={"/"} element={<Home />} />
        <Route
          key={Math.random()}
          path={"/create-profile"}
          element={<CreateProfile />}
        />
        <Route
          key={Math.random()}
          path={"/privacy-policy"}
          element={<PrivacyPolicy />}
        />
        <Route key={Math.random()} path={"*"} element={<NotFound />} />
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Router>
  );
}

export default App;
