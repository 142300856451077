import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/create-profile");
  }, [navigate]);

  return (
    <div className="h-screen w-full flex justify-center items-center">
      Go back home
    </div>
  );
};

export default NotFound;
