import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaSignInAlt,
  FaInfoCircle,
  FaRegStar,
  FaFolderOpen,
  FaUsers,
  FaPeopleArrows,
  FaYoutube,
} from "react-icons/fa";
import { IoIosHelpCircle, IoIosCloseCircle, IoIosSend } from "react-icons/io";
import { IoSearch, IoDocumentTextOutline } from "react-icons/io5";
import { FaLocationDot, FaLinkedinIn } from "react-icons/fa6";
import Hero from "../assets/images/hero-puzzle.svg";
import { BsBank2, BsFolder2Open, BsPencilSquare } from "react-icons/bs";
import { MdOutlineDone } from "react-icons/md";

const Home = () => {
  const [isInfraOpen, setIsInfraOpen] = useState(true);
  const [navOption, setNavOption] = useState(0);

  const year = new Date().getFullYear();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <div className="flex max-sm:flex-col gap-3 justify-between items-center px-6 py-3">
        <div>
          <Link to={"/"}>
            <p className="text-[#4c2c92]  text-[40px] max-lg:text-[32px] max-md:text-[24px] font-extrabold">
              RECRUITUSA
            </p>
          </Link>
        </div>
        <div className="flex gap-4">
          <div>
            <Link to={"/create-profile"} className="flex gap-2 items-center ">
              <FaSignInAlt className="text-[#0071bc]" />
              <p className="text-[20px] max-md:text-[14px] text-[#0071bc] font-medium">
                Sign up
              </p>
            </Link>
          </div>
          <div>
            <Link to={"/create-profile"} className="flex gap-2 items-center ">
              <IoIosHelpCircle className="text-[#0071bc]" />
              <p className="text-[20px] max-md:text-[14px] text-[#0071bc] font-medium">
                Help
              </p>
            </Link>
          </div>
          <div>
            <Link to={"/"} className="flex gap-2 items-center ">
              <IoSearch className="text-[#0071bc]" />
              <p className="text-[20px] max-md:text-[14px] text-[#0071bc] font-medium">
                Search
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="px-6 py-6 mb-8 bg-[#205493] flex flex-wrap max-sm:flex-col gap-4">
        <div className="w-1/2 max-sm:w-full">
          <div className="flex items-center gap-1">
            <IoSearch className="text-[#0071bc]" />
            <p className="text-[16px] max-md:text-[12px] font-medium text-white mb-1">
              Keywords
            </p>
          </div>
          <input
            type="text"
            name="keyword"
            placeholder="Job title, dept., agency, series or occupation"
            className="h-[45px] w-full px-4"
          />
        </div>
        <div className="w-1/3 max-sm:w-full">
          <div className="flex items-center gap-1">
            <FaLocationDot className="text-[#0071bc]" />
            <p className="text-[16px] max-md:text-[12px] font-medium text-white mb-1">
              Location or Remote
            </p>
          </div>
          <input
            type="text"
            name="location"
            placeholder="City, state, zip, country or type remote"
            className="h-[45px] w-full px-4"
          />
        </div>
        <div className="flex items-end">
          <button
            onClick={() => navigate("/create-profile")}
            type="button"
            className="bg-[#02bfe7] py-2 px-4 rounded-lg hover:opacity-40 h-[45px]"
          >
            Search
          </button>
        </div>
      </div>
      {isInfraOpen && (
        <div className="px-12 max-md:px-6">
          <div className="relative border border-[#02bfe7] bg-[#e1f3f8] border-l-8 px-6 py-6">
            <div className="flex items-center gap-5">
              <FaInfoCircle />
              <div>
                <p className="text-[24px] max-md:text-[16px] font-semibold">
                  Find Infrastructure Jobs Now
                </p>
                <p className="text-[16px] max-md:text-[12px]">
                  Build a Better America.{" "}
                  <Link
                    to={"/create-profile"}
                    className="underline text-[#0071bc]"
                  >
                    Apply now
                  </Link>
                </p>
              </div>
            </div>
            <IoIosCloseCircle
              onClick={() => setIsInfraOpen(false)}
              className="cursor-pointer absolute -right-[10px] -top-[10px] text-[24px]"
            />
          </div>
        </div>
      )}
      <div className="bg-[#112e51] px-24 max-lg:px-16 max-md:px-12 pt-12 mt-8">
        <div className="flex gap-12 max-[800px]:flex-col">
          <div className="w-1/2 max-[800px]:w-full">
            <img src={Hero} alt="flag" />
          </div>
          <div className="w-1/2 max-[800px]:w-full text-white mt-6">
            <p className="text-[40px] max-lg:text-[32px] max-md:text-[24px] font-bold mb-2">
              SHAPE AMERICA'S FUTURE
            </p>
            <p className="text-[32px] max-lg:text-[24px] max-md:text-[16px] font-bold mb-8">
              Find your fit in the federal government
            </p>
            <div className="flex gap-3 flex-wrap">
              <div className="flex justify-center">
                <Link
                  to={"/create-profile"}
                  className="bg-red-700 text-[22px] max-md:text-[14px] font-semibold px-8 py-4 rounded-lg text-white"
                >
                  Create profile
                </Link>
              </div>
              {/* <div className="flex justify-center">
                <Link
                  to={"https://id.me"}
                  target="_blank"
                  className="bg-[#205493] text-[22px] max-md:text-[14px] font-semibold px-8 py-4 rounded-lg text-white"
                >
                  Verify on id.me
                </Link>
              </div> */}
            </div>
          </div>
        </div>
        <div className="flex gap-2 justify-center mt-12 overflow-x-auto">
          <div
            onClick={() => setNavOption(0)}
            className={`cursor-pointer ${
              navOption === 0 ? "bg-white" : "bg-[#205493] text-white"
            } px-8 py-4 max-sm:px-4`}
          >
            <p className="font-semibold text-[20px] max-md:text-[13px]">
              Federal application process
            </p>
          </div>
          <div
            onClick={() => setNavOption(1)}
            className={`cursor-pointer ${
              navOption === 1 ? "bg-white" : "bg-[#205493] text-white"
            } px-8 py-4 max-sm:px-4`}
          >
            <p className="font-semibold text-[20px] max-md:text-[13px]">
              Create a RECRUITUSA profile
            </p>
          </div>
        </div>
      </div>
      {navOption === 1 ? (
        <div className="py-12 px-16 max-md:px-8 flex justify-center mb-8">
          <div className="w-full">
            <p className="flex justify-center text-center text-[32px] max-md:text-[24px] font-extrabold mb-10 max-md:mb-6">
              Create a RECRUITUSA profile
            </p>
            <div className="flex flex-wrap justify-center gap-6 mb-6">
              <div className="flex flex-col items-center">
                <FaRegStar className="text-amber-400 text-[40px] max-md:text-[24px] mb-6" />
                <p className="font-extrabold text-[20px] max-md:text-[14px] text-center mb-3">
                  Save your favorite jobs and searches
                </p>
                <p className="font-medium text-[16px] max-md:text-[12px] text-center mb-3">
                  Receive email updates from jobs you're interested in.
                </p>
              </div>
              <div className="flex flex-col items-center">
                <FaFolderOpen className="text-[#205493] text-[40px] max-md:text-[24px] mb-6" />
                <p className="font-extrabold text-[20px] max-md:text-[14px] text-center mb-3">
                  Upload your resumes and documents
                </p>
                <p className="font-medium text-[16px] max-md:text-[12px] text-center mb-3">
                  Save and manage resumes and documents for your application.
                </p>
              </div>
              <div className="flex flex-col items-center">
                <BsBank2 className="text-[#112e51] text-[40px] max-md:text-[24px] mb-6" />
                <p className="font-extrabold text-[20px] max-md:text-[14px] text-center mb-3">
                  Make your resume searchable
                </p>
                <p className="font-medium text-[16px] max-md:text-[12px] text-center mb-3">
                  Your resume will be visible to recruiters searching our
                  database.
                </p>
              </div>
              <div className="flex flex-col items-center">
                <MdOutlineDone className="text-green-800 text-[40px] max-md:text-[24px] mb-6" />
                <p className="font-extrabold text-[20px] max-md:text-[14px] text-center mb-3">
                  Apply for jobs in the federal government
                </p>
                <p className="font-medium text-[16px] max-md:text-[12px] text-center mb-3">
                  You can only apply online with a complete RECRUITUSA profile.
                </p>
              </div>
            </div>
            <div className="flex gap-3 justify-center flex-wrap">
              <div className="flex justify-center">
                <Link
                  to={"/create-profile"}
                  className="bg-red-700 text-[22px] max-md:text-[14px] font-semibold px-8 py-4 rounded-lg text-white"
                >
                  Create profile
                </Link>
              </div>
              {/* <div className="flex justify-center">
                <Link
                  to={"https://id.me"}
                  target="_blank"
                  className="bg-[#205493] text-[22px] max-md:text-[14px] font-semibold px-8 py-4 rounded-lg text-white"
                >
                  Verify on id.me
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="py-12 px-16 max-md:px-8 flex justify-center mb-8">
          <div className="w-4/5">
            <p className="flex justify-center text-[32px] text-center max-md:text-[24px] font-extrabold mb-10 max-md:mb-6">
              Learn about the Federal Application Process
            </p>
            <p className="text-[20px] max-md:text-[14px] mb-6">
              Below is an overview of the federal hiring process. This process
              is in place to make sure all applicants receive fair and equal
              opportunity.
            </p>
            <p className="text-[#0071bc] text-[24px] max-md:text-[14px] font-bold mb-4">
              RECRUITUSA
            </p>
            <div className="mb-6">
              <Link to={"/create-profile"}>
                <div className="flex items-center gap-4 mb-4">
                  <div className="w-[25px] h-[25px] bg-[#0071bc] rounded-full flex justify-center items-center text-white text-[14px] font-medium max-md:text-[10px]">
                    1
                  </div>
                  <div className="flex gap-1 items-center">
                    <BsPencilSquare />
                    <p className="text-[20px] max-md:text-[14px] font-semibold">
                      Create a RECRUITUSA profile
                    </p>
                  </div>
                </div>
                <p className="text-[16px] max-md:text-[12px] pl-10">
                  First, create and complete your profile to apply for any job
                  on RECRUITUSA.
                </p>
                <p className="text-[16px] max-md:text-[12px] pl-10">
                  With a RECRUITUSA profile, you can save jobs, automate job
                  searches, and manage everything you need to complete your
                  application, including resumes and required documents.
                </p>
              </Link>
            </div>
            <Link
              to={"https://id.me"}
              target="_blank"
              className="mb-6 text-[#0071bc]"
            >
              <div className="flex items-center gap-4 mb-4">
                <div className="w-[25px] h-[25px] bg-white border border-[#0071bc] rounded-full flex justify-center items-center text-[#0071bc] text-[14px]font-medium  max-md:text-[10px]">
                  2
                </div>
                <div className="flex gap-2 items-center">
                  <BsBank2 />
                  <p className="text-[20px] max-md:text-[14px] font-semibold">
                    Verify your profile on id.me
                  </p>
                </div>
              </div>
            </Link>
            <div className="mb-6 text-[#0071bc]">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-[25px] h-[25px] bg-white border border-[#0071bc] rounded-full flex justify-center items-center text-[#0071bc] text-[14px]font-medium  max-md:text-[10px]">
                  3
                </div>
                <div className="flex gap-2 items-center">
                  <IoSearch />
                  <p className="text-[20px] max-md:text-[14px] font-semibold">
                    Search for jobs
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-6 text-[#0071bc]">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-[25px] h-[25px] bg-white border border-[#0071bc] rounded-full flex justify-center items-center text-[#0071bc] text-[14px]font-medium  max-md:text-[10px]">
                  4
                </div>
                <div className="flex gap-2 items-center">
                  <IoDocumentTextOutline />
                  <p className="text-[20px] max-md:text-[14px] font-semibold">
                    Review job announcement
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-6 text-[#0071bc]">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-[25px] h-[25px] bg-white border border-[#0071bc] rounded-full flex justify-center items-center text-[#0071bc] text-[14px]font-medium  max-md:text-[10px]">
                  5
                </div>
                <div className="flex gap-2 items-center">
                  <BsFolder2Open />
                  <p className="text-[20px] max-md:text-[14px] font-semibold">
                    Prepare your application in RECRUITUSA
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-6 text-[#0071bc]">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-[25px] h-[25px] bg-white border border-[#0071bc] rounded-full flex justify-center items-center text-[#0071bc] text-[14px]font-medium  max-md:text-[10px]">
                  6
                </div>
                <div className="flex gap-2 items-center">
                  <MdOutlineDone />
                  <p className="text-[20px] max-md:text-[14px] font-semibold">
                    Submit your application to the agency
                  </p>
                </div>
              </div>
            </div>
            <div className="border-y flex items-center gap-4 py-3 mb-6">
              <IoIosSend />
              <p className="text-[20px] max-md:text-[14px] font-semibold">
                Transition to Agency
              </p>
            </div>
            <p className="text-[20px] max-md:text-[14px] font-semibold text-green-700 mb-3">
              Agency
            </p>
            <div className="mb-6 text-green-700">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-[25px] h-[25px] bg-white border border-green-700 rounded-full flex justify-center items-center text-green-700 text-[14px]font-medium  max-md:text-[10px]">
                  7
                </div>
                <div className="flex gap-2 items-center">
                  <BsBank2 />
                  <p className="text-[20px] max-md:text-[14px] font-semibold">
                    Agency reviews application
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-6 text-green-700">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-[25px] h-[25px] bg-white border border-green-700 rounded-full flex justify-center items-center text-green-700 text-[14px]font-medium  max-md:text-[10px]">
                  8
                </div>
                <div className="flex gap-2 items-center">
                  <FaUsers />
                  <p className="text-[20px] max-md:text-[14px] font-semibold">
                    Interview
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-6 text-green-700">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-[25px] h-[25px] bg-white border border-green-700 rounded-full flex justify-center items-center text-green-700 text-[14px]font-medium  max-md:text-[10px]">
                  9
                </div>
                <div className="flex gap-2 items-center">
                  <FaPeopleArrows />
                  <p className="text-[20px] max-md:text-[14px] font-semibold">
                    Agency selects candidates
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-center bg-[#02bfe7] px-12 py-8">
        <div className="w-4/5 flex flex-col items-center gap-3">
          <p className="text-[24px] max-md:text-[16px] font-semibold text-white">
            Federal Hiring Myths
          </p>
          <p className="text-[20px] max-md:text-[14px] text-[#fdb81e]">
            "Resumes are scanned for keywords by an automated system"
          </p>
          <p className="text-white text-[20px] max-md:text-[14px]">
            Partially true: Real people, Human Resource (HR) specialists, do
            look at resumes, however some agencies also use an automated system
            to review resumes. For the agencies that do use an automated system,
            the resume review process has two parts:
          </p>
          <p className="text-white text-[20px] max-md:text-[14px]">
            An automated application review process to include all the required
            areas of the applicant's eligibility and qualifications for the job.
            A manual review, by the HR Specialist, of an applicant's resume to
            validate the information in the application package.
          </p>
        </div>
      </div>
      <div className="py-8 px-12 max-md:px-6  text-white bg-[#112e51]">
        <div className="flex justify-between">
          <div>
            <p className="text-[48px] max-lg:text-[32px] max-md:text-[24px] font-extrabold mb-4">
              RECRUITUSA
            </p>
            <p className="text-[14px] mb-4">
              RECRUITUSA is a Opens in a new windowUnited States Office of
              Personnel Management website.
            </p>
          </div>
          <div>
            <div className="flex gap-4 mb-6">
              <FaYoutube />
              <FaLinkedinIn />
            </div>
            <div>
              <Link to={"/privacy-policy"} className="text-[14px]">
                Privacy Policy
              </Link>
            </div>
            <div>
              <Link to={"/privacy-policy"} className="text-[14px]">
                Terms & Conditions
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="pt-4">
            <p className="text-[14px] text-white">@RecruitUSA {year}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
